.list-item {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 0.5rem;
}

.sliding-pane {
  cursor: -webkit-grab;
  background-color: #ffffff;
  /* background-image: url("../../images/glasscrack.png"); */
  background-size: "contain";
  color: rgba(255, 255, 255, 0.9);
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  font-size: 1.25em;
  font-weight: 600;
}

.sliding-pane:active {
  cursor: -webkit-grabbing;
}

.background {
  align-self: stretch;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
